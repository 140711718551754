<template>
  <b-card-code title="Patient List">

    <!-- input search -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="filter"
            type="search"
            placeholder="Type to filter data"
          />
        </div>
      </b-form-group>
    </div>
    <!-- table -->
    <b-editable-table
      id="patient-list"
      v-model="users"
      :filter="filter"
      :busy="loading"
      responsive
      hover
      :per-page="perPage"
      :current-page="currentPage"
      bordered
      class="editable-table"
      :fields="fields"
      primary-key="id"
      @input-change="handleInput"
    >
      <!-- A virtual column -->
      <template #cell(index)="data">
        {{ data.index = data.id }}
      </template>
      <template #cell(created_at)="data">
        <span v-if="data.value">{{ formatDateAssigned(data.value) }}</span>
        <span v-else>-</span>
      </template>
      <template #cell(dob)="data">
        <span v-if="data.value">{{ formatDob(data.value) }}</span>
        <span v-else>-</span>
      </template>
      <template #cell(type_of_diabetes)="data">
        <span v-if="data.value">{{ data.value }}</span>
        <span v-else> Please select</span>
      </template>
      <template #cell(cde_call_required)="data">
        <span v-if="data.value">{{ data.value }}</span>
        <span v-else> No</span>
      </template>
      <template #cell(id)="data">
        <span v-if="data.value">
          <feather-icon
            icon="EyeIcon"
            class="mr-50"
            @click="OnPatientClick(data.value)"
          /></span>
        <span v-else>-</span>
      </template>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle" />
          <strong>Loading...</strong>
        </div>
      </template>
    </b-editable-table>

    <b-pagination
      v-model="currentPage"
      pills
      :total-rows="rowsCount"
      :per-page="perPage"
      aria-controls="patient-list"
    />

    <p class="mt-3">
      Current Page: {{ currentPage }}
    </p>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BFormGroup, BFormInput, BPagination, BSpinner,
} from 'bootstrap-vue'

import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import Ripple from 'vue-ripple-directive'

import BEditableTable from 'bootstrap-vue-editable-table'
// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

export default {
  components: {
    BSpinner,
    BCardCode,
    BEditableTable,
    // BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    // BFormSelect,
    // BTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isSaving: false,
      filter: '',
      sortBy: 'fname',
      sortDesc: false,
      isAddNewUserSidebarActive: false,
      roleOptions: [],
      jobTitleOptions: [],
      showOptions: [],
      pageLength: 15,
      perPage: 6,
      currentPage: 1,
      dir: true,
      updatedRow: {},
      fields: [
        {
          id: 1,
          label: 'First Name',
          key: 'fname',
          type: 'text',
          editable: true,
          sortable: true,
          class: 'make-200',
        },
        {
          id: 2,
          label: 'Last Name',
          key: 'lname',
          type: 'text',
          editable: true,
          sortable: true,
          class: 'make-200',
        },
        {
          id: 3,
          label: 'Email',
          key: 'email',
          type: 'text',
          editable: true,
          sortable: true,
          class: 'make-200',
        },
        {
          id: 4,
          label: 'Phone',
          key: 'phone_number',
          type: 'text',
          editable: true,
          sortable: true,
          class: 'make-200',
        },

        {
          id: 5,
          label: 'Date of birth',
          key: 'dob',
          type: 'date',
          editable: true,
          sortable: true,
          class: 'make-200',
          validate: this.validateDate,
          'date-format-options': {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          },
        },
        {
          id: 6,
          label: 'Type of diabetes Note: If T1 eligible for NDSS subsidy.',
          key: 'diabetes_type_name',
          type: 'select',
          class: 'make-200',
          editable: true,
          options: [
            { value: '', text: 'Please select' },
            { value: 'Type 1', text: 'Type 1' },
            { value: 'Type 2', text: 'Type 2' },
            { value: 'Gestational', text: 'Gestational' },
            { value: 'Other', text: 'Other' },
          ],
          // validate: this.validateSelect,
        },
        {
          id: 7,
          label: 'CDE call required?',
          key: 'cde_call_required',
          editable: false,
          type: 'text',
          sortable: true,
        },
        {
          id: 8,
          label: 'Date CDE called',
          key: 'consult_date',
          editable: true,
          type: 'date',
          sortable: true,
          validate: this.validateDate,
          class: 'make-200',
          'date-format-options': {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          },
        },
        {
          id: 9,
          label: 'CDE Name',
          key: 'doctor_name',
          type: 'text',
          editable: true,
          sortable: true,
          class: 'make-200',
        },
        {
          id: 10,
          label: 'Call Notes',
          key: 'cde_comments',
          type: 'text',
          editable: true,
          class: 'make-200',
        },
        {
          id: 11,
          label: 'Which product is the patient currently using?',
          key: 'current_device_used',
          type: 'select',
          editable: true,
          class: 'make-200',
          options: [
            { value: 'Please Select', text: 'Please Select' },
            { value: 'FreeStyle Libre', text: 'FreeStyle Libre' },
            { value: 'FreeStyle Libre 2', text: 'FreeStyle Libre 2' },
            { value: 'Dexcom G4', text: 'Dexcom G4' },
            { value: 'Dexcom G5', text: 'Dexcom G5' },
            { value: 'Dexcom G6', text: 'Dexcom G6' },
            { value: 'Medtronic Guardian 2/Enlite sensor', text: 'Medtronic Guardian 2/Enlite sensor' },
            { value: 'Medtronic Guardian 3', text: 'Medtronic Guardian 3' },
            { value: 'Blood glucose meter', text: 'Blood glucose meter' },
            { value: 'Other', text: 'Other' },
          ],
          validate: this.validateSelect,
        },
        {
          id: 12,
          label: 'If T1, NDSS form completed? (Note: Near real-time approval if completed via NDSS HCP portal)',
          key: 'ndss_approved',
          type: 'select',
          editable: true,
          options: [
            { value: 'Please Select', text: 'Please Select' },
            { value: 'Yes', text: 'Yes' },
            { value: 'No', text: 'No' },
          ],
          validate: this.validateSelect,
          class: 'make-200',
        },
        {
          id: 13,
          label: 'Which product selected on NDSS form?(FSL2 or list other CGM)',
          key: 'glucose_monitor_name',
          type: 'select',
          editable: true,
          options: [
            { value: 'Please Select', text: 'Please Select' },
            { value: 'FSL2', text: 'FSL2' },
            { value: 'MDT G4', text: 'MDT G4' },
            { value: 'Dex G6', text: 'Dex G6' },
            { value: 'N/A', text: 'N/A' },
          ],
          validate: this.validateSelect,
          class: 'make-200',
        },
        {
          id: 14,
          label: 'If T2, helped patient get FSL2 product experience?',
          key: 'did_t2_help_fsl2',
          type: 'select',
          editable: true,
          options: [
            { value: 'Please Select', text: 'Please Select' },
            { value: 'Yes', text: 'Yes' },
            { value: 'No', text: 'No' },
          ],
          validate: this.validateSelect,
          class: 'make-200',
        },
        {
          id: 15,
          label: 'Emailed / provided link to FSL2 webinar training? www.freestylelibre.com.au/freewebinar ',
          key: 'has_fsl2_link_given',
          type: 'select',
          editable: true,
          options: [
            { value: 'Please Select', text: 'Please Select' },
            { value: 'Yes', text: 'Yes' },
            { value: 'No', text: 'No' },
          ],
          validate: this.validateSelect,
        },
        {
          id: 16,
          label: 'Patient consented to Careprograms diabetes support?',
          key: 'consent_for_cp',
          type: 'select',
          editable: true,
          options: [
            { value: '', text: 'Please Select' },
            { value: 'Yes', text: 'Yes' },
            { value: 'No', text: 'No' },
          ],
          validate: this.validateSelect,
        },
        {
          id: 17,
          label: 'Patient consented to Abbott support via training / marketing materials?',
          key: 'consent_for_abbot',
          type: 'select',
          editable: true,
          options: [
            { value: '', text: 'Please Select' },
            { value: 'Yes', text: 'Yes' },
            { value: 'No', text: 'No' },
          ],
          validate: this.validateSelect,
        },
        {
          id: 18,
          label: 'Helped patient download LibreLink App and add in Practice? Practice ID: \'MedAdvisorcare?\'',
          key: 'help_to_download',
          type: 'select',
          editable: true,
          options: [
            { value: '', text: 'Please Select' },
            { value: 'Yes', text: 'Yes' },
            { value: 'No', text: 'No' },
          ],
          validate: this.validateSelect,
        },
        {
          id: 19,
          label: 'Created at',
          key: 'created_at',
          editable: false,
          sortable: true,
        },
        {
          id: 20,
          label: 'Action',
          key: 'id',
          editable: false,
          sortable: false,
        },
      ],
      users: [],
      searchTerm: '',
      loading: false,
    }
  },
  computed: {
    rowsCount() {
      return this.users.length
    },

    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },

    getRoleRoute() {
      const user = JSON.parse(localStorage.getItem('userData'))
      if (user.role === 4) {
        return 'cde-patient-add-visit-data'
      }
      return 'pharmacy-patient-visit-authenticated'
    },
    isSuperAdmin() {
      const user = JSON.parse(localStorage.getItem('userData'))
      return user.role === 1 || user.role === 2
    },
  },

  async mounted() {
    this.loading = true
    // Use toast
    const toast = useToast()

    store
      .dispatch('userStore/fetchCdePatients')
      .then(response => {
        const users = response.data
        this.users = users
        this.loading = false
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    handleInput(data) {
      if (Object.keys(this.updatedRow).length === 0) {
        this.updatedRow = {
          user_pub_id: data.id,
          index: data.index,
          rkey: data.field.key,
          rval: data.value,
          [data.field.key]: data.value,
        }
        // save it
        this.isSaving = true
        store
          .dispatch('userStore/saveCDEPatientData', this.updatedRow)
          .then(() => {
            // this.updatedRow = {}
            this.updatedRow = {
              user_pub_id: data.id,
              index: data.index,
              rkey: data.field.key,
              rval: data.value,
              [data.field.key]: data.value,
            }
            // this.$bvToast.toast('Data has been saved', {
            //   title: 'Success',
            //   variant: 'success',
            //   solid: true,
            // })
            // this.isSaving = false
          })
          .catch(() => {
            this.updatedRow = {}
            this.$bvToast.toast(
              'We could not process your action this time. please try again.',
              {
                title: 'Error',
                variant: 'danger',
                solid: true,
              },
            )
          })
      } else {
        // console.log(`updated_row_else:${JSON.stringify(this.updatedRow)}`)
        // this.updatedRow = { ...this.updatedRow, [data.field.key]: data.value }
        this.updatedRow = {}
      }
      // console.log(JSON.parse(JSON.stringify(this.users)))
      // const idx = data.index
      // .${data.field.key} = data.value
      // console.log(`vb2:${JSON.parse(JSON.stringify(this.updatedRow))}`)
      // console.log(JSON.parse(JSON.stringify(this.users[idx])))
    },
    validateDate() {
      // const year = new Date(value).getFullYear()
      // if (year > 2003) {
      //   return {
      //     valid: false,
      //     errorMessage: 'Must be above 19',
      //   }
      // }
      return { valid: true }
    },
    // eslint-disable-next-line no-unused-vars
    validateSelect(value, options) {
      // if (value === 0) {
      //   return {
      //     valid: false,
      //     errorMessage: 'Please select an option',
      //   }
      // }
      return { valid: true }
    },
    onFiltered(filteredItems) {
      this.rowsCount = filteredItems.length
      this.currentPage = 1
      this.users.count = filteredItems.length
    },

    OnPatientClick(pid) {
      const route = this.$router.resolve({
        name: this.getRoleRoute, params: { id: pid },
      })
      window.open(route.href, '_self')
    },

    refetchData() {
      return this.$forceUpdate()
    },

    formatDateAssigned(dateString) {
      const options = {
        year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric',
      }
      return new Date(dateString).toLocaleDateString(undefined, options)
    },
    formatDob(dateString) {
      const options = {
        year: 'numeric', month: 'long', day: 'numeric',
      }
      return new Date(dateString).toLocaleDateString(undefined, options)
    },
    // formatBoolToString(val) {
    //   console.log(`ndss=${val}`)
    //   let boolString = '-'
    //   if (val === 1) {
    //     boolString = 'Yes'
    //   } else if (val === 0) {
    //     boolString = 'No'
    //   }
    //   return boolString
    // },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style>
table.editable-table {
  margin: 25px;
}

table.editable-table td {
  vertical-align: middle;
}

.editable-table .data-cell {
  padding: 8px;
  vertical-align: middle;
}

.editable-table .custom-checkbox {
  width: 50px;
}

.name-col {
  width: 120px;
}

.department-col {
  width: 150px;
}

.age-col {
  width: 100px;
}

.date-col {
  width: 200px;
}

.is-active-col {
  width: 100px;
}

.make-200 {
  width: 200px;
}

ul {
  list-style: none;
}
table {
        height: 100%
    }
</style>
